#root {
  width: 100%;
  height: 100vh;
  text-align: center;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  width: 100%;
  /* position: fixed;
  top: 0; */
  padding: 10px 0;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4c078c;
  width: 100%;
  gap: 10px;
  color: white;
  /* position: fixed;
  top: 0; */
  padding: 40px 0 20px 0;
}

.nav img {
  /* filter: brightness(0.5); */
  width: 150px;
}

.body {
  padding: 0 80px;
  margin-bottom: 10px;
}

.banner {
  display: flex;
  padding: 10px 5px;
  gap: 10px;
}

.banner .img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img img {
  border-radius: 17px;
}
.banner .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
}

.banner h4,
.banner p {
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 590px) {
  .footer {
    flex-direction: column;
  }

  .footer img {
    width: 100px !important;
  }
}

.am-time {
  display: none;
}

.Mui-disabled {
  color: rgba(
    0,
    0,
    0,
    0.38
  ); /* Adjust the color to make disabled dates visible */
}

.MuiDigitalClock-list .Mui-disabled {
  display: none;
}
